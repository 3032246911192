<template>
  <v-data-table
    :headers="headers"
    :items="localBonuses"
    :item-class="getRowClass"
    :expanded.sync="expanded"
    item-key="company_id"
    fixed-header
    single-expand
    show-expand
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Всього бонусів: {{ total.toLocaleString() }} ₴</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
      <td v-if="item.company_name !== 'MATE'" class="text-start">
        <v-btn
          icon
          @click="expand(!isExpanded)"
          class="v-data-table__expand-icon"
          :class="{ 'v-data-table__expand-icon--active': isExpanded }"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </td>
    </template>
    <template v-slot:item.company_name="{ item }">
      <v-tooltip v-if="item.company_name === 'MATE'" top>
        <template v-slot:activator="{ on, attr }">
          <span v-bind="{ attr }" v-on="on">{{ item.company_name }}</span>
        </template>
        Можно витратити в будь якій торгівельній точці
      </v-tooltip>
      <div v-else>
        <img v-if="item.company_logo" :src="`${baseURL}/${item.company_logo}`" class="mr-2" alt="logo" width="24" height="24" />
        <span v-if="item.company_name">{{ item.company_name }}</span>
      </div>
    </template>
    <template v-slot:item.amount="{ item }">
      <span>{{ item.amount.toLocaleString() }}</span>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-data-table
          :headers="servicePointsHeaders"
          :items="item.servicePoints"
          :loading="loading"
          :options.sync="options"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
          :server-items-length="getServicePointsTotalCount"
          :item-class="getSPRowClass"
        >
          <template v-slot:item.name="{ item }">
            <router-link
              :to="{ name: 'ServicePointCard', params: { id: item.company_id, servicePointId: item.id } }"
              class="text-decoration-none"
            >
              {{ item.name }}
            </router-link>
          </template>
          <template v-slot:item.address_text="{ item }">
            <router-link
              :to="{ name: 'ServicePointCard', params: { id: item.company_id, servicePointId: item.id } }"
              class="text-decoration-none"
            >
              {{ item.address_text }}
            </router-link>
          </template>
        </v-data-table>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import cloneDeep from '@/mixins/cloneDeep'

export default {
  name: 'BonusesTable',

  mixins: [cloneDeep],

  props: {
    bonuses: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API_URL,
      expanded: [],
      localBonuses: [],
      options: {},
      loading: false,
      headers: [
        {
          text: 'Компанія',
          value: 'company_name',
        },
        {
          text: 'Сума бонусів, ₴',
          value: 'amount',
        },
      ],
      servicePointsHeaders: [
        {
          text: 'Торгівельні точки компанії',
          value: 'name',
        },
        {
          text: 'Адреса',
          value: 'address_text',
        },
      ],
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    bonuses(val) {
      this.localBonuses = this.cloneObjectDeep(val)
    },

    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('servicePoints', ['servicePoints']),
    ...mapGetters('servicePoints', ['getServicePointsTotalCount']),

    currentCompanyId() {
      return this.$route.params.id
    },

    total() {
      return this.localBonuses.reduce((prev, curr) => prev + curr.amount, 0)
    },
  },

  methods: {
    ...mapActions('servicePoints', ['loadServicePoints']),

    initialize() {
      this.localBonuses = this.cloneObjectDeep(this.bonuses)
    },

    async paginateTo() {
      this.loading = true
      const params = {
        companyId: this.expanded.length && this.expanded[0].company_id ? this.expanded[0].company_id : this.currentCompanyId,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      }
      await this.loadServicePoints(params)
      if (params.companyId) {
        this.localBonuses.find((bonus) => bonus.company_id === +params.companyId).servicePoints = [
          ...this.servicePoints.map((item) => ({
            ...item,
            company_id: params.companyId,
            address_text: `${item.address.city}, ${item.address.street}, ${item.address.building_number}`,
          })),
        ]
      }
      this.loading = false
    },

    getRowClass(item) {
      if (item.company_name === 'MATE') {
        return 'bg-mate'
      }
    },
    getSPRowClass() {
      return 'bg-sp'
    },
  },
}
</script>

<style lang="scss">
.bg-mate {
  background-color: #bbdefb;
}
.bg-sp {
  background-color: #f8f8f8;
}
.v-data-table__expanded {
  &.v-data-table__expanded__content {
    td[colspan='3'] {
      padding: 0 !important;
    }
  }
}
</style>
